<template>
  <HeaderWidget v-if="isWidgetPage" />
  <Header v-else-if="!isEvisoBusinessAuth && !isBookingSchemeEditor" />
  <HeaderSchemeEditor v-else-if="isBookingSchemeEditor" />
  <main :class="{ _widget: isWidgetPage }">
    <VNav v-if="isMenuShown" />
    <router-view />
    <NoticeList v-if="isNotices" />
  </main>
  <ModalContainer />
</template>

<script setup>
import { useWindowSize } from "@vueuse/core";
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import NoticeList from "@/components/notice/NoticeList.vue";
import VNav from "@/components/Nav.vue";
import ModalContainer from "@/components/modals/ModalContainer.vue";
import Header from "@/components/Header.vue";
import HeaderSchemeEditor from "@/components/HeaderSchemeEditor.vue";
import HeaderWidget from "@/components/HeaderWidget.vue";

const store = useStore();
const route = useRoute();

const { width, height } = useWindowSize();

watch(
  width,
  (value) => {
    store.commit({
      type: "setWidth",
      value,
    });
  },
  { immediate: true }
);

watch(
  height,
  (value) => {
    store.commit({
      type: "setHeight",
      value,
    });
  },
  { immediate: true }
);

const isNotices = computed(() => {
  return store.state.notices.isNotices;
});

const isEvisoBusinessAuth = computed(() => {
  return route.name === "auth-biz" || route.name === "auth-eviso";
});

const isMenuShown = computed(() => {
  return store.getters.screenType !== "desktop" && store.state.general.isMenu;
});

const isBookingSchemeEditor = computed(() => {
  return route.name === "booking-scheme";
});

const isWidgetPage = computed(() => {
  return route.name === "widget";
});
</script>

<style lang="scss">
main {
  position: relative;
  display: flex;
  min-height: calc(100% - 68px);
  height: calc(100% - 68px);

  &._widget {
    min-height: calc(100% - 88px);
    height: calc(100% - 88px);
  }

  @include mobile {
    flex-direction: column;
  }
}
</style>
