import { createRouter, createWebHistory } from "vue-router";
import authMiddleware from "./auth-middleWare";
import MapPage from "@/components/pages/home/MapPage.vue";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "home",
    component: MapPage,
    children: [
      {
        path: ":catId",
        name: "home/:catId",
        component: MapPage,
      },
    ],
  },
  {
    path: "/restaurants",
    name: "restaurants",
    component: () =>
      import("../components/pages/restaurants/RestaurantPage.vue"),
  },
  {
    path: "/restaurants/:catId",
    name: "restaurant/:catId",
    component: () =>
      import("../components/pages/restaurants/RestaurantSite.vue"),
  },
  {
    path: "/account",
    name: "account",
    component: () => import("../components/pages/AccountPage.vue"),
    children: [
      {
        path: "administrators",
        name: "administrators",
        component: () =>
          import("../components/pages/administrators/AdministratorsPage.vue"),
        children: [
          {
            path: "",
            name: "administrators",
            component: () =>
              import(
                "../components/pages/administrators/AdministratorsTableWrapper.vue"
              ),
          },
          {
            path: "form",
            name: "administrators/form",
            component: () =>
              import(
                "../components/pages/administrators/AdministratorsForm.vue"
              ),
            children: [
              {
                path: ":id",
                name: "administrators/form/:id",
                component: () =>
                  import(
                    "../components/pages/administrators/AdministratorsForm.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "clients",
        name: "clients",
        redirect: "/account/clients/b2b",
        children: [
          {
            path: "b2b",
            name: "clients/b2b",
            component: () =>
              import("../components/pages/clients/ClientsB2BTable.vue"),
          },
          {
            path: "b2c",
            name: "clients/b2c",
            component: () =>
              import("../components/pages/clients/ClientsB2CTable.vue"),
          },
          {
            path: "form/:id",
            name: "clients/form/:id",
            component: () =>
              import("../components/pages/clients/ClientForm.vue"),
            children: [
              {
                path: "counterparties",
                name: "clients/form/counterparties",
                component: () =>
                  import(
                    "../components/pages/clients/ClientFormCounterparties.vue"
                  ),
              },
              {
                path: "invoices",
                name: "clients/form/invoices",
                component: () =>
                  import("../components/pages/clients/ClientFormInvoices.vue"),
              },
              {
                path: "acts",
                name: "clients/form/acts",
                component: () =>
                  import("../components/pages/clients/ClientFormActs.vue"),
              },
            ],
          },
          {
            path: ":id",
            name: "clients/:id",
            component: () =>
              import("../components/pages/clients/ClientInfo.vue"),
          },
        ],
      },
      {
        path: "statistics",
        name: "statistics",
        component: () =>
          import("../components/pages/statistics/StatisticsPage.vue"),
      },
      // {
      //   path: "sms",
      //   name: "sms",
      //   component: () =>
      //     import("../components/pages/catering/RestaurantsPage.vue"),
      // },
      {
        path: "",
        name: "counterparties",
        component: () =>
          import("../components/pages/counterparty/CounterpartyPage.vue"),
      },
      {
        path: "counterparties",
        name: "counterparties",
        redirect: "/account/counterparties/jur",
        children: [
          {
            path: "jur",
            name: "counterparties/jur",
            component: () =>
              import("../components/pages/counterparty/EntityTable.vue"),
          },
          {
            path: "ip",
            name: "counterparties/ip",
            component: () =>
              import("../components/pages/counterparty/IpTable.vue"),
          },
          {
            path: "form",
            name: "counterparties/form",
            component: () =>
              import("../components/pages/counterparty/CounterpartyForm.vue"),
            children: [
              {
                path: ":id",
                name: "counterparties/form/:id",
                component: () =>
                  import(
                    "../components/pages/counterparty/CounterpartyForm.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "employees",
        name: "employees",
        component: () =>
          import("../components/pages/employees/EmployeePage.vue"),
      },
      {
        path: "documents",
        name: "documents",
        component: () =>
          import("../components/pages/documents/DocumentsPage.vue"),
        children: [
          {
            path: "",
            name: "documents/table",
            component: () =>
              import("../components/pages/documents/DocumentsTable.vue"),
          },
          {
            path: "problematic",
            name: "documents/problematic",
            component: () =>
              import(
                "../components/pages/documents/DocumentsProblematicBills.vue"
              ),
          },
          {
            path: ":id",
            name: "documents/:id",
            component: () =>
              import("../components/pages/documents/DocumentDownload.vue"),
          },
        ],
      },
      {
        path: "catering",
        name: "catering",
        component: () =>
          import("../components/pages/catering/RestaurantsPage.vue"),
        children: [
          {
            path: "",
            name: "catering",
            component: () =>
              import("../components/pages/catering/RestaurantsTable.vue"),
          },
          {
            path: "form",
            name: "catering/form",
            component: () =>
              import("../components/pages/catering/RestaurantsForm.vue"),
            children: [
              {
                path: ":id",
                name: "catering/form/:id",
                component: () =>
                  import("../components/pages/catering/RestaurantsForm.vue"),
              },
            ],
          },
          {
            path: "reviews",
            name: "biz-reviews",
            component: () =>
              import("../components/pages/reviews-biz/ReviewsPage.vue"),
          },
          {
            path: "moderator-reviews",
            name: "moderator-reviews",
            component: () =>
              import("../components/pages/reviews/ReviewsPage.vue"),
          },
          {
            path: "business-booking",
            name: "catering/business-booking",
            component: () =>
              import(
                "../components/pages/business-booking/BusinessBookingPage.vue"
              ),
            children: [
              {
                path: "",
                name: "catering/business-booking",
                component: () =>
                  import(
                    "../components/pages/business-booking/BusinessBookingCateringsList.vue"
                  ),
              },
              {
                path: ":catId",
                name: "catering/business-booking/:catId",
                component: () =>
                  import(
                    "../components/pages/business-booking/BusinessBookingCateringReservations.vue"
                  ),
              },
            ],
          },
          {
            path: ":id",
            name: "account/catering/id",
            component: () =>
              import("../components/pages/catering/RestaurantsInfo.vue"),
            beforeEnter: (to, from, next) => {
              if (
                store.getters.role === "ADMIN" ||
                store.getters.role === "MANAGER" ||
                store.getters.role === "HEAD" ||
                store.getters.role === "MAJOR" ||
                store.getters.role === "MARKETER"
              ) {
                next();
              } else {
                next(`/${to.params.id}`);
              }
            },
          },
        ],
      },
      {
        path: "favorites",
        name: "favorites",
        component: () =>
          import("../components/pages/favorites/FavoritesPage.vue"),
      },
      {
        path: "bookings",
        name: "bookings",
        component: () =>
          import("../components/pages/booking-client-list/BookingPage.vue"),
      },
      {
        path: "reviews",
        name: "reviews",
        component: () => import("../components/pages/reviews/ReviewsPage.vue"),
      },
      {
        path: "business-booking",
        name: "business-booking",
        component: () =>
          import(
            "../components/pages/business-booking/BusinessBookingPage.vue"
          ),
        children: [
          {
            path: "",
            name: "business-booking",
            component: () =>
              import(
                "../components/pages/business-booking/BusinessBookingCateringsList.vue"
              ),
          },
          {
            path: ":catId",
            name: "business-booking/:catId",
            component: () =>
              import(
                "../components/pages/business-booking/BusinessBookingCateringReservations.vue"
              ),
          },
        ],
      },
      {
        path: "journal",
        name: "journal",
        beforeEnter: (_, __, next) => {
          if (store.getters.role === "ADMIN" || store.getters.role === "ROOT") {
            next();
          } else {
            next({ name: "home" });
          }
        },
        component: () => import("../components/pages/journal/JournalPage.vue"),
      },
      {
        path: "settings",
        name: "settings",
        children: [
          {
            path: "",
            name: "settings",
            component: () =>
              import("../components/pages/settings/SettingsPage.vue"),
          },
          {
            path: "managers",
            name: "settings-managers",
            component: () =>
              import("../components/pages/settings/SettingsPage.vue"),
          },
          {
            path: "moderators",
            name: "settings-moderators",
            component: () =>
              import("../components/pages/settings/SettingsPage.vue"),
          },
          {
            path: "secretaries",
            name: "settings-secretaries",
            component: () =>
              import("../components/pages/settings/SettingsPage.vue"),
          },
        ],
      },
      {
        path: "tariffs",
        name: "tariffs",
        component: () =>
          import("../components/pages/./tariffs/TariffsPage.vue"),
        children: [
          {
            path: "",
            name: "tariffs",
            component: () =>
              import("../components/pages/tariffs/TariffsMain.vue"),
          },
          {
            path: "form",
            name: "tariffs/form",
            component: () =>
              import("../components/pages/./tariffs/TariffsForm.vue"),
            children: [
              {
                path: ":id",
                name: "tariffs/form/:id",
                component: () =>
                  import("../components/pages/./tariffs/TariffsForm.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "packages",
        name: "packages",
        component: () =>
          import("../components/pages/packages/PackagesPage.vue"),
        children: [
          {
            path: "",
            name: "packages",
            component: () =>
              import("../components/pages/packages/PackagesTableWrapper.vue"),
          },
          {
            path: "form",
            name: "packages/form",
            component: () =>
              import("../components/pages/./packages/PackagesForm.vue"),
            children: [
              {
                path: ":id",
                name: "packages/form/:id",
                component: () =>
                  import("../components/pages/packages/PackagesForm.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "presents",
        name: "presents",
        component: () =>
          import("../components/pages/packages/PackagesPage.vue"),
        children: [
          {
            path: "",
            name: "presents",
            component: () =>
              import(
                "../components/pages/packages/PackagesPresentTableWrapper.vue"
              ),
          },
          {
            path: "form",
            name: "presents/form",
            component: () =>
              import("../components/pages/./packages/PresentForm.vue"),
            children: [
              {
                path: ":id",
                name: "presents/form/:id",
                component: () =>
                  import("../components/pages/packages/PresentForm.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "payments",
        name: "payments",
        component: () =>
          import("../components/pages/./payments/PaymentsPage.vue"),
        children: [
          {
            path: "",
            name: "payments",
            component: () =>
              import("../components/pages/./payments/PaymentsList.vue"),
          },
          {
            path: "history",
            name: "payments/history",
            component: () =>
              import("../components/pages/./payments/PaymentsHistoryList.vue"),
          },
          {
            path: ":id",
            name: "payments/:id",
            component: () =>
              import("../components/pages/payments/PaymentsSettings.vue"),
          },
        ],
      },
      {
        path: "widgets",
        name: "widgets",
        component: () => import("../components/pages/widget/WidgetPage.vue"),
      },
    ],
  },

  {
    path: "/org",
    name: "auth-eviso",
    component: () => import("../components/pages/auth/EvisoAuthPage.vue"),
  },
  {
    path: "/biz",
    name: "auth-biz",
    component: () => import("../components/pages/auth/BusinessAuthPage.vue"),
  },
  {
    path: "/booking-scheme",
    name: "booking-scheme",
    component: () => import("../components/pages/booking/BookingPage.vue"),
    children: [
      {
        path: ":catId",
        name: "booking-scheme",
        component: () => import("../components/pages/booking/BookingPage.vue"),
      },
    ],
  },
  {
    path: "/booking",
    name: "booking",
    component: () =>
      import("../components/pages/client-booking/ClientBookingPage.vue"),
    children: [
      {
        path: ":catId",
        name: "booking",
        component: () =>
          import("../components/pages/client-booking/ClientBookingPage.vue"),
      },
    ],
  },
  {
    path: "/w",
    name: "widget",
    component: () => import("../components/pages/w/ClientBookingPage.vue"),
    children: [
      {
        path: ":id",
        name: "widget",
        component: () => import("../components/pages/w/ClientBookingPage.vue"),
      },
    ],
  },
  {
    path: "/booking-edit",
    name: "booking-edit",
    component: () =>
      import("../components/pages/booking-edit/BusinessBookingEditPage.vue"),
    children: [
      {
        path: ":catId",
        name: "booking-edit/:catId",
        component: () =>
          import(
            "../components/pages/booking-edit/BusinessBookingEditPage.vue"
          ),
      },
    ],
  },
  {
    path: "/business-booking-seats",
    name: "business-booking-seats",
    component: () =>
      import(
        "../components/pages/business-booking-seats/BusinessBookingSeatsPage.vue"
      ),
    children: [
      {
        path: ":catId",
        name: "business-booking-seats/:catId",
        component: () =>
          import(
            "../components/pages/business-booking-seats/BusinessBookingSeatsPage.vue"
          ),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory("./"),
  strict: true,
  routes,
});

router.beforeEach(authMiddleware);

export default router;
