<template>
  <div class="inner">
    <div class="main-block">
      <div class="lines">
        <div class="line _active" />
        <div class="line _active" />
        <div class="line" />
        <div class="line" />
      </div>
      <h2>Выбор даты</h2>
      <VDataPickerBooking
        v-if="isLoaded"
        inline
        @update="date = $event"
        :dateVal="date"
        :next-year="nextYear"
        @changeMonth="$emit('changeMonth', $event)"
        min-date
      />
    </div>
    <div class="buttons">
      <VButton outline @click="onPrevClickHandler" class="button">
        Назад
      </VButton>
      <VButton @click="onNextClickHandler" class="button"> Далее </VButton>
    </div>
    <IconPopupCross
      v-if="screenWidth !== 'mobile'"
      class="modal-cross"
      @click="$store.dispatch('closeModal')"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import IconPopupCross from "@/components/icons/IconPopupCross.vue";

const store = useStore();

const screenWidth = computed(() => {
  return store.getters.screenType;
});
</script>

<script>
import VButton from "@/components/ui/VButton.vue";
import VDataPickerBooking from "@/components/ui/VDataPickerBooking.vue";
import FilterVisitorsModal from "@/components/modals/FilterVisitorsModal.vue";
import FilterDurationModal from "@/components/modals/FilterDurationModal.vue";
export default {
  name: "FilterDateModal",

  components: {
    VDataPickerBooking,
    VButton,
  },

  props: {
    freeMonthDays: {
      type: Array,
    },
    nextYear: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      date: "",
      disabledDate: true,
      isLoaded: false,
    };
  },

  methods: {
    onPrevClickHandler() {
      this.$store.commit({
        type: "setFilterDate",
        value: this.date,
      });
      this.$store.dispatch("openModal", {
        value: {
          component: FilterVisitorsModal,
          props: {},
        },
      });
    },
    onNextClickHandler() {
      this.$store.commit({
        type: "setFilterDate",
        value: this.date,
      });
      this.$store.dispatch("openModal", {
        value: {
          component: FilterDurationModal,
          props: {},
        },
      });
    },
  },

  watch: {
    date(val) {
      this.$emit("changeDate", val);
    },
    dateVal: {
      handler(val) {
        if (val) {
          const date = new Date(val);

          const updatedDate = new Date(
            date.getTime() + (300 + date.getTimezoneOffset()) * 60000
          );

          const day = updatedDate.getDate();

          this.disabledDate = this.freeMonthDays[day - 1] !== 1;
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.date = this.$store.state.clientRestaurants.filter.date || new Date();
    this.isLoaded = true;
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin: 32px 0 0;
}

.title {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #222222;
}

.main-block {
  padding-bottom: 32px;

  @include mobile {
    padding: 64px;
  }
}

.message {
  width: 336px;
  margin-top: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #222222;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  width: 100%;

  @include mobile {
    flex-direction: column;
    margin-top: 32px;
  }
}

.button {
  height: 40px;
  padding: 9px 38px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  @include mobile {
    &:first-child {
      margin-bottom: 16px;
    }
  }
}
</style>

<style lang="scss" scoped>
.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 776px;
  width: 640px;
  max-width: 640px;
  margin: auto;
  padding: 32px 56px 40px 56px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  @media screen and (max-width: 841px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px 0 #0000000d;
    border-radius: 20px 20px 0 0;
    padding: 32px 24px 24px 24px;
  }
}

h2 {
  margin-bottom: 24px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.modal-cross {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.timers {
  max-width: 776px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  > div {
    width: calc(50% - 8px);
  }
}

.lines {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 56px;

  @include mobile {
    width: calc(100% - 32px);
    height: 8px;
    justify-content: flex-start;
    align-items: center;
    margin: 0 16px;
    padding: 0 4px;
    border-radius: 8px;
    background-color: white;
  }
}

.line {
  width: 204px;
  height: 8px;
  background-color: #adadad;

  @include mobile {
    width: calc(20% - 4px);
    border-radius: 8px;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }

  &._active {
    background-color: #f18e35;
    cursor: pointer;
  }
}
</style>
